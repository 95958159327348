function displayPopinLogin(redirectUrl){

    $.fancybox.open({
        src  : '/popin-login?redirecturl='+encodeURIComponent(redirectUrl),
        type : 'iframe',
        opts : {
            afterShow : function( instance, current ) {

            },
            iframe : {
                css : {
                    width : '600px',
                    height : '100%',
                    maxwidth  : '80%',
                    maxheight : '100%',
                    margin: 0
                }
            }
        }
    });
}

function displayContact(e){
    e.preventDefault();

    $.fancybox.open({
        src  : '/contact',
        type : 'iframe',
        opts : {
            afterShow : function( instance, current ) {},
            iframe : {
                preload : false,
                css : {
                    width : '600px',
                    height : '610px',
                    maxwidth  : '80%',
                    maxheight : '80%',
                    margin: 0,

                }
            },
            autoSize: false,
            closeExisting: false,
            clickOutside: false,
            dblclickOutside: false,
            hideOnOverlayClick:false, // outside of fancybox click disabled
        },
        helpers:  {
            overlay : {
                closeClick  : false
            }
        }
    });
}

$(document).ready(function(){


    $('.popin-login').click(function(e){
        e.preventDefault();

        displayPopinLogin(window.location.href);
    })
    $('.nav-link-contact').click(function(e){
        displayContact(e)

    })
    $('.popin-contact').click(function(e){
        displayContact(e)
    });


            $('.slider-wrap').each(function(){
                var responsive = $(this).find('.responsive')
                var btnRight = $(this).find('.btn-right');
                var btnLeft = $(this).find('.btn-left');

                responsive.on('init',function(event, slick, direction){
                    if (slick.slideCount <= slick.options.slidesToShow) {
                        slick.$slider.addClass('slick-no-slide');
                    }
                    else {
                        slick.$slider.removeClass('slick-no-slide');
                    }
                    // left
                });

                responsive.slick({
                    dots: false,
                    infinite: true,
                    speed: 300,
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    prevArrow:btnLeft,
                    nextArrow:btnRight,
                    responsive: [
                        {
                            breakpoint: 1024,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 3,
                                infinite: true,
                                dots: false,
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 2
                            }
                        },
                        {
                            breakpoint: 480,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }

                    ]
                })



            })



});