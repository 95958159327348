/*
    A simple jQuery modalcustom (http://github.com/kylefox/jquery-modalcustom)
    Version 0.9.1
*/
!function (o) {
    "object" == typeof module && "object" == typeof module.exports ? o(require("jquery"), window, document) : o(jQuery, window, document)
}(function (o, t, i, e) {
    var s = [], l = function () {
        return s.length ? s[s.length - 1] : null
    }, n = function () {
        var o, t = !1;
        for (o = s.length - 1; o >= 0; o--) s[o].$blocker && (s[o].$blocker.toggleClass("current", !t).toggleClass("behind", t), t = !0)
    };
    o.modalcustom = function (t, i) {
        var e, n;
        if (this.$body = o("body"), this.options = o.extend({}, o.modalcustom.defaults, i), this.options.doFade = !isNaN(parseInt(this.options.fadeDuration, 10)), this.$blocker = null, this.options.closeExisting) for (; o.modalcustom.isActive();) o.modalcustom.close();
        if (s.push(this), t.is("a")) if (n = t.attr("href"), this.anchor = t, /^#/.test(n)) {
            if (this.$elm = o(n), 1 !== this.$elm.length) return null;
            this.$body.append(this.$elm), this.open()
        } else this.$elm = o("<div>"), this.$body.append(this.$elm), e = function (o, t) {
            t.elm.remove()
        }, this.showSpinner(), t.trigger(o.modalcustom.AJAX_SEND), o.get(n).done(function (i) {
            if (o.modalcustom.isActive()) {
                t.trigger(o.modalcustom.AJAX_SUCCESS);
                var s = l();
                s.$elm.empty().append(i).on(o.modalcustom.CLOSE, e), s.hideSpinner(), s.open(), t.trigger(o.modalcustom.AJAX_COMPLETE)
            }
        }).fail(function () {
            t.trigger(o.modalcustom.AJAX_FAIL);
            var i = l();
            i.hideSpinner(), s.pop(), t.trigger(o.modalcustom.AJAX_COMPLETE)
        }); else this.$elm = t, this.anchor = t, this.$body.append(this.$elm), this.open()
    }, o.modalcustom.prototype = {
        constructor: o.modalcustom, open: function () {
            var t = this;
            this.block(), this.anchor.blur(), this.options.doFade ? setTimeout(function () {
                t.show()
            }, this.options.fadeDuration * this.options.fadeDelay) : this.show(), o(i).off("keydown.modalcustom").on("keydown.modalcustom", function (o) {
                var t = l();
                27 === o.which && t.options.escapeClose && t.close()
            }), this.options.clickClose && this.$blocker.click(function (t) {
                t.target === this && o.modalcustom.close()
            })
        }, close: function () {
            s.pop(), this.unblock(), this.hide(), o.modalcustom.isActive() || o(i).off("keydown.modalcustom");

        }, block: function () {
            this.$elm.trigger(o.modalcustom.BEFORE_BLOCK, [this._ctx()]), this.$body.css("overflow", "hidden"), this.$blocker = o('<div class="' + this.options.blockerClass + ' blocker current"></div>').appendTo(this.$body), n(), this.options.doFade && this.$blocker.css("opacity", 0).animate({opacity: 1}, this.options.fadeDuration), this.$elm.trigger(o.modalcustom.BLOCK, [this._ctx()])
        }, unblock: function (t) {
            !t && this.options.doFade ? this.$blocker.fadeOut(this.options.fadeDuration, this.unblock.bind(this, !0)) : (this.$blocker.children().appendTo(this.$body), this.$blocker.remove(), this.$blocker = null, n(), o.modalcustom.isActive() || this.$body.css("overflow", ""))
        }, show: function () {
            this.$elm.trigger(o.modalcustom.BEFORE_OPEN, [this._ctx()]), this.options.showClose && (this.closeButton = o('<a href="#close-modalcustom" rel="modalcustom:close" class="close-modalcustom ' + this.options.closeClass + '">' + this.options.closeText + "</a>"), this.$elm.append(this.closeButton)), this.$elm.addClass(this.options.modalcustomClass).appendTo(this.$blocker), this.options.doFade ? this.$elm.css({
                opacity: 0,
                display: "inline-block"
            }).animate({opacity: 1}, this.options.fadeDuration) : this.$elm.css("display", "inline-block"), this.$elm.trigger(o.modalcustom.OPEN, [this._ctx()])
        }, hide: function () {
            this.$elm.trigger(o.modalcustom.BEFORE_CLOSE, [this._ctx()]), this.closeButton && this.closeButton.remove();
            var t = this;
            this.options.doFade ? this.$elm.fadeOut(this.options.fadeDuration, function () {
                t.$elm.trigger(o.modalcustom.AFTER_CLOSE, [t._ctx()])
            }) : this.$elm.hide(0, function () {
                t.$elm.trigger(o.modalcustom.AFTER_CLOSE, [t._ctx()])
            }), this.$elm.trigger(o.modalcustom.CLOSE, [this._ctx()])
        }, showSpinner: function () {
            this.options.showSpinner && (this.spinner = this.spinner || o('<div class="' + this.options.modalcustomClass + '-spinner"></div>').append(this.options.spinnerHtml), this.$body.append(this.spinner), this.spinner.show())
        }, hideSpinner: function () {
            this.spinner && this.spinner.remove()
        }, _ctx: function () {
            return {elm: this.$elm, $elm: this.$elm, $blocker: this.$blocker, options: this.options}
        }
    }, o.modalcustom.close = function (t) {
        if (o.modalcustom.isActive()) {
            t && t.preventDefault();
            var i = l();
            return i.close(), i.$elm
        }
    }, o.modalcustom.isActive = function () {
        return s.length > 0
    }, o.modalcustom.getCurrent = l, o.modalcustom.defaults = {
        closeExisting: !0,
        escapeClose: !0,
        clickClose: !0,
        closeText: "Close",
        closeClass: "",
        modalcustomClass: "modalcustom",
        blockerClass: "jquery-modalcustom",
        spinnerHtml: '<div class="rect1"></div><div class="rect2"></div><div class="rect3"></div><div class="rect4"></div>',
        showSpinner: !0,
        showClose: !0,
        fadeDuration: null,
        fadeDelay: 1
    }, o.modalcustom.BEFORE_BLOCK = "modalcustom:before-block", o.modalcustom.BLOCK = "modalcustom:block", o.modalcustom.BEFORE_OPEN = "modalcustom:before-open", o.modalcustom.OPEN = "modalcustom:open", o.modalcustom.BEFORE_CLOSE = "modalcustom:before-close", o.modalcustom.CLOSE = "modalcustom:close", o.modalcustom.AFTER_CLOSE = "modalcustom:after-close", o.modalcustom.AJAX_SEND = "modalcustom:ajax:send", o.modalcustom.AJAX_SUCCESS = "modalcustom:ajax:success", o.modalcustom.AJAX_FAIL = "modalcustom:ajax:fail", o.modalcustom.AJAX_COMPLETE = "modalcustom:ajax:complete", o.fn.modalcustom = function (t) {
        return 1 === this.length && new o.modalcustom(this, t), this
    }, o(i).on("click.modalcustom", 'a[rel~="modalcustom:close"]', o.modalcustom.close), o(i).on("click.modalcustom", 'a[rel~="modalcustom:open"]', function (t) {
        t.preventDefault(), o(this).modalcustom()
    })
});